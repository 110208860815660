import Connect from 'app/redux/connect'
import Redux from 'app/interface/redux'
import Form from 'components/base/form'
import React, { PureComponent } from 'react'
import Overlay from 'react-loading-overlay'
import Input from 'components/base/form/interface/input'
import Basic from 'app/redux/actions/pages/business/basic'
import { businessService } from '../../../../../../../../../app/service/business'
import { businessManagerService } from 'app/service/business-manager'

class Facebook extends PureComponent<Redux> {
  state = {
    account: {
      valid: true,
      prior: 0,
    },
    events: [],
    pixels: [],
    instagram: [],
    loading: false,
  }

  // Lifecycle
  componentDidMount() {
    const { fb_account_id } = this.props.pages.business.main
    this.updateState('account', fb_account_id, 'prior')
    this.initFetch()
  }

  componentDidUpdate(old) {
    old = old.pages.business.main.fb_account_id
    const now = this.props.pages.business.main.fb_account_id
    if (old !== now) {
      this.updateState('account', old, 'prior')
    }
  }

  // Fetchers
  shouldFetch = () => {
    const { prior } = this.state.account
    const current = this.props.pages.business.main.fb_account_id
    if (prior !== current && !isNaN(current)) {
      this.fetch()
    }
  }

  initFetch = () => {
    const { fb_account_id } = this.props.pages.business.main
    if (!!fb_account_id) {
      this.fetch()
    }
  }

  fetch = async () => {
    this.setLoading(true)
    const results = await Promise.all([this.fetchFBConnectedAccounts(), this.fetchInstagram()])

    this.setState({
      events: results[0].offlineEventSets,
      pixels: results[0].pixels,
      instagram: results[1],
      loading: false,
    })

    this.setLoading(false)
  }

  fetchFBConnectedAccounts = async () => {
    const accountID = this.getColumn('account')
    return await businessManagerService.getFBConnectedAccounts(
      `${accountID}`,
      this.props.pages.business.id,
      this.props.pages.business.main.fb_ad_authorizer_id ?? undefined
    )
  }

  fetchInstagram = async () => {
    try {
      if (this.props.pages.business.id) {
        return await businessService.getInstagramPages(this.props.pages.business.id)
      } else {
        return await businessManagerService.getInstagramPagesByAccountID(
          this.props.pages.business.main.fb_account_id
        )
      }
    } catch (e) {
      // in the event there is no pages, return empty array
      return []
    }
  }

  // Setters
  setLoading = (state: boolean) => {
    this.setState({
      loading: state,
    })
  }

  updateState = (who: any, state: any, column: any = null) => {
    return new Promise((resolve: any) => {
      if (column) {
        this.setState(
          _ => ({
            ..._,
            [who]: {
              ..._[who],
              [column]: state,
            },
          }),
          resolve
        )
      } else {
        this.setState(
          _ => ({
            ..._,
            [who]: state,
          }),
          resolve
        )
      }
    })
  }

  // Getters
  getColumn = (who: string) => {
    const { main } = this.props.pages.business
    switch (who) {
      case 'events':
        return main.offline_event_set_id
      case 'pixels':
        return main.fb_dataset_id
      default:
        return main.fb_account_id
    }
  }

  // Output
  render() {
    const { events, pixels, instagram, loading } = this.state
    const { main } = this.props.pages.business
    return (
      <Overlay active={loading} spinner text="Fetching information about this business' ad account">
        <Form
          save={false}
          onChange={Basic.update}
          onSubmit={() => null}
          groups={[
            {
              header: 'Facebook',
              rows: [
                {
                  column: 6,
                  fields: [
                    {
                      column: 'fb_account_id',
                      text: 'Account ID',
                      type: 'regular',
                      value: main.fb_account_id || '',
                      onBlur: this.shouldFetch,
                    } as Input,
                    {
                      column: 'fb_page_id',
                      text: 'Page ID',
                      type: 'regular',
                      value: main.fb_page_id || null,
                    } as Input,
                  ],
                },
                {
                  column: 6,
                  fields: [
                    {
                      column: 'offline_event_set_id',
                      text: 'Offline ID',
                      type: 'select',
                      options: events,
                      value:
                        events.find((x: any) => x.id === main.offline_event_set_id) ?? {
                          id: main.offline_event_set_id,
                        } ??
                        null,
                      selector: {
                        getOptionLabel: x => (x.name ? `${x.name} - ${x.id}` : x.id),
                        getOptionValue: x => x.id,
                        value: 'id',
                        clearable: true,
                      },
                      onChange: (column: string, value: any) => Basic.update(column, value.id),
                    } as Input,
                    {
                      column: 'fb_dataset_id',
                      text: 'Dataset ID',
                      type: 'select',
                      options: pixels,
                      value:
                        pixels.find((x: any) => x.id === main.fb_dataset_id) ?? {
                          id: main.fb_dataset_id,
                        } ??
                        null,
                      selector: {
                        getOptionLabel: x => (x.name ? `${x.name} - ${x.id}` : x.id),
                        getOptionValue: x => x.id,
                        value: 'id',
                        clearable: true,
                      },
                      onChange: (column: string, value: any) => Basic.update(column, value.id),
                    } as Input,
                  ],
                },
                {
                  column: 6,
                  fields: [
                    {
                      column: 'instagram_id',
                      text: 'Instagram Page',
                      type: 'select',
                      options: instagram,
                      value:
                        instagram.find((x: any) => x.id === main.instagram_id) ?? {
                          id: main.instagram_id,
                        } ??
                        null,
                      selector: {
                        getOptionLabel: x => x.username ?? x.id,
                        getOptionValue: x => x.id,
                        value: 'id',
                        clearable: true,
                      },
                      onChange: (column: string, value: any) => Basic.update(column, value.id),
                    } as Input,
                  ],
                },
                {
                  column: 12,
                  fields: [
                    {
                      column: 'use_conversions_api',
                      text: 'Use Conversions API?',
                      type: 'toggle',
                      value: main.use_conversions_api,
                    } as Input,
                  ],
                },
              ],
            },
          ]}
        />
        <div className="col-sm-12 pb-5 text-info">
          <span>
            If the Conversions API is toggled, your offline events will be uploaded to the new
            Conversions API. When using the Conversions API, please ensure a Dataset ID is
            configured and this field is toggled on. If using the Offline Conversions API, ensure
            the Offline Event Set ID is set and this is toggled off. Please use this at your
            discretion, as it is considered an experimental API at the moment. If you have any
            questions, please reach out to your manager.
          </span>
        </div>
      </Overlay>
    )
  }
}

export default Connect(Facebook)
